@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');

:root {
  --main-color: #ce1014; 
  --gradient-pink: linear-gradient(0deg, #107436,#9cff6c);
    --gradient-blue: linear-gradient(0deg, #074964 , #7bd7ff );
    --gradient-light: linear-gradient(0deg, #fff9cf , #fff190 );

}

body,
html {
  font-size: 16px;
  overflow-x: hidden;
  font-family: "Roboto Flex", sans-serif;
  color : #333333;
  padding: 0!important;
  ;
}

a {
  color: inherit !important;
  text-decoration: none !important;
  color: inherit !important;
}
.logo img {
  width: 100%;
  margin: auto;
  display: block;
  max-width: 100%;
}
.highlight-blk{
  background: var(--gradient-pink);
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  overflow: hidden;
}

/* .highlight-blk img{
  width: 50px;
} */
.highlight-blk p{
  margin-bottom: 0;
}
.highlight-blk marquee p {
  margin-bottom: 0;
  font-size: 15px;
}
.highlight-blk marquee p a{margin-right:10px;border-right: solid 1px #fff;padding-right:10px;}
.header-contact {
  vertical-align: middle;
  /* height: 100%; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 26px;
  padding-bottom: 20px;
}
.header-contact >div  img {
height: 20px;
}
.pd-0{
padding: 0;
} 
.highlight-blk img {
width: 33px;
}
.header-contact h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--main-color);
}
.header-contact h3 svg {
  margin-right: 10px;
}
.header-contact > div {
margin-bottom: 0;
width: 40%;
text-align: center;
}
.header-contact  .border-tab{  
  background: var(--gradient-pink);
  padding: 9px 14px;
  border-radius: 16px;
  margin-bottom: 0;
  transition: 0.5s;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 20px 18px -10px #0000003d;
  text-transform: capitalize;
  line-height: 21px;
}
/* .border-tab:hover{
background: var(--gradient-red);
} */
.d-flex{
display: flex;
}
.space-even{
justify-content: space-evenly;
}
.col-md-2.highlight-tab .space-even img {
width: 19px !important; 

} 
.menu {
width: 100%;
}
.border-tab{
position: relative;
} 
 
.menu-toggle {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: none;
  
}
p.d-flex.space-even.highlight-blk:before {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  background: #fff;
  left: -35px;
  top: -13px;
  transform: rotate(59deg);
}

.submenu {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
  background: var(--gradient-blue);
  margin: auto;
  z-index: 999;
  position: relative;
  border-radius: 5px;
  padding: 16px;
}
.submenu li:last-child {
  border-right: 0;
}
.submenu li {
  padding: 0 20px;
  position: relative;
  width: max-content;
  color: #fff;
  margin: 0 0px;
  font-weight: 500;
  text-transform: uppercase;
  border-right: 1px solid #ffffff3b;
  font-size: 15px;
  cursor: pointer;
  font-family: "Roboto Serif", serif;
}

.submenu li:last-child {
  border-bottom: none;
}

.menu:hover .submenu {
  display: flex;
}

.sub-submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0%;
  background-color: #fff; 
  padding-left: 0;
  list-style-type: none;    
  padding: 0px 10px;
  box-shadow: 0px 0px 9px #bfbfbf;
  border-radius: 2px;
}
.sub-submenu li {
  color: #000;
  font-size: 15px;
  border-right: 0;
  padding: 7px 0px;
}
.sub-submenu li:hover{
color: #245190;
cursor: pointer;
}

.submenu li:hover .sub-submenu {
  display: block;
}
 
.sub-submenu li{
  color: #000;
  text-transform: capitalize;
  font-family: "Manrope", font-family;
}
.submenu li i.bi.bi-chevron-down {
  margin-left: 5px;
  font-size: 11px;
}
.header-contact .border-tab a {
position: relative;
}
.contact-tab {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contact-tab img {
  display: block;
  margin: auto;
  width: 47px;
  border: 2px solid #246d8b;
  border-radius: 50%;
  padding: 8px;
}

.contact-tab p {
  font-size: 15px;
}

.contact-tab p a {
  font-size: 13px; 
  color: #000  !important;
  text-transform: lowercase;
  line-height: 16px;
  font-family: "Roboto Flex", sans-serif;
}

.contact-tab p {
  color: #246d8b;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: "Roboto Serif", serif;
}

.contact-tab p a span {
  color: #666666;
}

.contact-tab > div {
  margin: 0 20px;
}

 
.breadcrumb-blk h2 { 
  font-size: 40px;
}

p {
  font-size: 15px;
  line-height: 29px;
}

ul li {
  line-height: 29px;
  font-size: 16px;
  margin-bottom: 10px;
}

.about-us img.img-fluid {
  width: 100%;
}

.online-reg {
  position: fixed;
  right: 30px;
  z-index: 9;
  top:240px;
  filter: drop-shadow(0px 0px 6px #ddd);
  width: 190px;
}

.online-reg img {
  width: 100%;
}

.highlight-tab {
  background: var(--gradient-red);
  height: 35px;
  height: 20px;
  position: relative;
}

.highlight-tab a {
  padding: 1px;
}

.header-contact .border-tab {
  position: relative;
  font-family: "Roboto Serif", serif;
} 
.header-blk {
  padding: 8px;
  width: 90%;
  margin: auto;
}
.vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight{
  background: var(--gradient-blue);
  padding: 20px;
  border-radius: 16px;
  color: #fff;
  box-shadow: 3px 30px 20px -20px #626262;
  width: 95%;
  margin-top: -110px;
  min-height: 320px;
}
.vision-mission p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7;
  overflow: hidden;
}
.vision-mission .highlight marquee {
  height: 210px;
}
.vision-mission .highlight{
  color: #333333;
background: var(--gradient-light);
}
.vision-mission .highlight a{
  background: var(--gradient-blue);
}
.vision-mission .mission span, .vision-mission .vission span, .vision-mission .motto span {
  font-weight: 600;
}
.vision-mission .col-md-3 {
  padding: 0;
}
.vision-mission h3 img {
  width: 50px;
}

.vision-mission .mission ul li {
  margin-bottom: 0;
}

.vision-mission .mission ul {
  padding-left: 15px;
}

.vision-mission .mission p {
  margin-bottom: 0;
}
.vision-mission h3 {
  text-align: center;
  margin-top: 0;
  font-size: 18px;
  font-weight: normal; 
  text-transform: uppercase;
  font-family: "Roboto Serif", serif;
  font-weight: 600;
}
.vision-mission .highlight marquee p {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vision-mission .highlight marquee p a {
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 5px; 
  color: #fff !important;
}

.bg-image{
  position: relative;
  z-index: 9;
}
.bg-image:before{
  content: "";
  width: 50%;
  height: 300px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/lotus.png);
  background-repeat: no-repeat;
  z-index: -1;
}
.bg-image:after{
  content: "";
  width: 50%;
  height: 300px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/lotus.png);
  background-repeat: no-repeat;
  background-position: right;
  z-index: -1
}

.title {
  text-align: center;
  margin-bottom: 30px;
  color: #444444;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.title .title-gif {
  width: max-content;
  margin: auto;
  border-bottom: 4px solid #f76d6d;
  padding: 2px 10px;
}

.title .title-gif img {
  width: 55px;
}
 




.button a {
  position: relative; 
  display: block; 
  width: max-content; 
} 
.upcoming h4{
  width: max-content;
  margin: auto;
  color: var(--main-color);
  position: relative;
  font-size: 19px;
}
.upcoming h4:before {
  content: "";
  width: 80px;
  position: absolute;
  top: 120%;
  background: #24508f;
  height: 3px;
  transform: translate(-50%, -50%);
  left: 50%;
}
.abt-button{
  position: relative;
  transition: 0.5s;
}
.button.abt-button:hover {
  margin-top: -5px;
} 
.abt-button:before{  
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/Read-more-before.png);
  background-size: 84% 84%;
  transform: unset;
  background-repeat: no-repeat;
  background-position: top center;
  top: 86%;
  z-index: 9;


}
.view-gal:before{  
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/Read-more-before.png);
  background-size: 100% 100%; 
  top: 100%;
  background-repeat: no-repeat;
}
.header-contact .border-tab:hover:before {
  top: 105%;
}

p.border-tab:hover {
  margin-top: -10px;
}
.ml-0{
  margin-left: 0 !important
}

.button {
  position: relative;
  width: max-content; 

} 
p span {
  font-weight: 600;
}

.button a:hover {
  background: var(--gradient-red);
}

.min-ht {
  min-height: 600px;
}

.about-us {
  margin-top: -40px;
  position: relative;
  padding: 130px 0 40px;
}

.about-us .title {
  margin-bottom: 4px !important;
}

.about-us>div {
  position: relative;
  z-index: 9;
}
 

.news {
  width: 100%;
  margin: auto;
  padding: 30px 30px;
  border-radius: 6px;
  background: var(--gradient-light);
}

.news-blk marquee {
  min-height: 210px;
  height: 410px;
}.title.center {
  justify-content: center;
}

.newsarea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.news .button a {
  margin: auto;
} 

.news-event {
  margin-top: 40px;
  position: relative;
}
.news-event:before{
  content: "";
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0%;
  top: 80%;
  transform: translate(-0%, -50%);
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/event-bg1.png);
  background-repeat: no-repeat;
  z-index: -1;
}
.news-event:after{
  content: "";
  width: 50%;
  height: 100%;
  top: 50%;
  position: absolute;
  right: 0%;
  transform: translate(-0%, -50%);
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/event-bg2.png);
  background-repeat: no-repeat;
  background-position: right;
  z-index: -1
}
.news-event>div{
  width: 90%;
  margin: auto;
}
.events-blk .button {
  margin-top: 20px;
}

.events {
  width: 90%;
  margin: auto;
  background: var(--gradient-light);
  padding: 50px 30px;
  border-radius: 6px; 
}

.evt-img {
  position: relative;
}
.evt-img img{height:320px;object-fit: cover;border-radius: 11px;}

.evt-img .date {
  position: absolute;
  right: 4px;
  top: 4px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 9px;
  font-size: 13px;
}

.evt-title {    
  
  padding: 8px 13px;
  border-radius: 10px;
}

.evt-title h3 {
  margin: 0;
  font-size: 18px;
  text-transform: uppercase;
  min-height: 50px;
  text-align: center;
  margin-top: 12px;
  font-family: "Roboto Serif", serif;
}

.evt-img .date span {
  display: block;
}

.evt-blk {
  background: #fff;
  padding: 6px;
  border-radius: 16px;
  width: 95%;
}

.evt-blk p img {
  width: 14px !important;
  float: left;
  vertical-align: middle;
  margin-top: 8px;
  margin-right: 7px;
}

.evt-blk p span {
  float: right;
  color: #666666;
  font-weight: normal;
}

.evt-content {
  padding: 30px 20px 2px;padding-top:10px;
}

.evt-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 14px;
  color: #666666;
}

.evt-content p:last-child {
  font-size: 14px;
}
.news-event .title {
  justify-content: center;
}

.news-event .title> div:nth-child(1) {
  width: 90%;
}
.newsarea p {
  margin-bottom: 0;
}
.newsarea a {
  background: var(--gradient-blue);
  color: #fff !important;
  padding: 5px 10px;
  border-radius: 9px 10px;
}
.news-event .title> div:nth-child(2) {margin-right: 0;padding: 0 2px;}
 
 

.message {
  width: 90%;
  padding: 100px 0;
  margin: auto;
}


 #myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.erp_sec img {
  width: 200px;
  display: inline-block;
}
.erp_sec h4.imgheading {
  text-align: center;
}
.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}
 

.facilities-main {  
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/facility-bg.png);
  background-size: 100% 100%;
}
.facilities {
  width: 50%;
  padding: 100px 0;
  margin: auto;
}

.faci-blk {
  width: 80%;
  margin: auto;
  text-align: center;
  background: #eaeaea;
  padding: 40px 20px;
  border-radius: 7px;
  transition: 0.5s;
}

.faci-blk:hover {
  box-shadow: 0px 0px 20px 0px #c5c5c5;
}

.faci-blk img {
  height: 70px;
  margin-bottom: 20px;
}

.faci-blk p {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}
.message-block {
  display: flex;
  align-items: flex-start;
}

img.quotes {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 80px !important;
}

.princi-Name {
  background: var(--gradient-blue);
  color: #fff;
  text-align: center;
  padding: 10px 0 10px;
  width: 95%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -9%);
  position: absolute;
    text-transform: uppercase;
}

.princi-Name h3 {
  font-size: 18px;
  font-family: "Roboto Serif", serif;
} 
.princi-Name p {
  margin-bottom: 0;
  font-size: 17px;
}
.message-block > div:nth-child(1) {
  width: 70%;
}

.message-block > div:nth-child(2) {
  width: 30%;
  margin-left: 30px;
  position: relative;
}
.message-block > div:nth-child(2) img {
  max-height: 510px;
}
.message-title{
  text-align: center;
  background: #4b8226;
  color: #fff;
  padding: 5px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: "Roboto Serif", serif;
  font-size: 21px;
  letter-spacing: 1px;
}

.birthday-block{
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/birthday-bg.png);
  background-size: 100% 100%;
  padding: 60px 10px 100px; 
}
.message-blk{
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/message-bg.png);
  background-size: 100% 100%;
    padding: 30px 30px 60px;
}
.birthday-block .birthday-theme {
  width: 70%;
  margin: auto;
}

.birthday-block .birthday-theme img {
  width: 80px !important;
  margin: auto;
  margin-bottom: 10px;
}
.birthday-content {
  width: 100%;
  text-align: center;
}

.birthday-content img {
  margin-bottom: 0;
  line-height: 20px;
  font-size: 13px;
  width: 80px !important;
  height: 80px;
  object-fit: contain;
  object-position: center;
  margin: auto;
  border-radius: 50%;
  border: 2px solid #e158de;
}

.birthday-content p {
  margin-bottom: 0;
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto Serif", serif;
  margin-top: 11px;
}
.upcoming {
  text-align: center;
  padding-right: 80px;
  margin-bottom: 40px;
  text-transform: uppercase;
}  
.birthday-content p.primary {
  font-weight: normal;
  font-size: 13px;
}
 .addColor h4{
  color: #333333 !important
 }

.birth-topper { 
  margin-top: 40px;
  width: 100%; 
  padding: 60px auto 40px;
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/birthday-main-bg.png);
  background-size: 100% 100%;
}
.birthday-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.birthday-flex >  div {
  width: 30%;
  margin-bottom: 20px;
}

.topper-img img {
  width: 110px !important;
  margin: auto;
}

.topper-img {
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/topper-bg.png);
  
  padding: 43px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

p.percent {
  text-align: center;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-pink);
  border-radius: 50%;
  color: #fff;
  margin: auto;
  margin-bottom: 10px;
}

.topper-cont {
  text-align: center;
  margin-bottom: 20px;
}

.topper-cont p {
  margin-bottom: 4px;
}

.tpr-block {
  position: relative;
  padding-left: 60px;
  background: linear-gradient(181deg, #ffffffb0, #ffffff52);
  margin-bottom: 12px;
}

.tpr-class-img {
  position: absolute;
  left: -40px;
  top: -50px;
}
.gallery:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/gallery-before.png);
  background-size: auto 100%;
}
.gallery {
  padding: 30px 0;
  position: relative;
}
.gallery .button {
  margin: auto;
  margin-top: 00px;
}
.gallery-main{
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/gallery-bg.png);
  background-size: 100% 100%;
  padding-bottom: 40px;
  padding-top: 30px;
}
.gallery-blk img {
  height: 260px;
  object-fit: contain;  
 background: #ddd; 
  width: 100%;

}

.gallery-blk {
  position: relative;
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  background: #0000009c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  opacity: 0;
  transition: 0.5s;
}
.overlay:hover {
  opacity: 1;
}

.overlay h3 {
  font-size: 18px;
  margin-bottom: 0;
  /* font-weight: 600; */
  margin-top: 0;
   font-family: "Roboto Serif", serif;
}

.overlay p {
  font-weight: 200;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.footer {
  width: 90%;
  margin: 60px auto 40px;
  color: #fff;
  margin-bottom: 0;
}
 
.quick-link h3 , .cont-dtl h3{
  font-family: "Roboto Serif", serif;
  letter-spacing: 1px;
}

.inr-event-img img{    height: 250px;
  object-fit: contain;}
  .title img {margin-left: 17px;}
  .upcoming img {
    width: 50px;
}
  .title p {
      margin-bottom: 0;
      letter-spacing: 2px;
  }
  .change-title h3 {
    text-align: center;
    font-size: 24px;
    font-family: "Roboto Serif", serif;
    color: #246d8b;
}
.title h3, .title h1{
  font-size: 28px;
  margin-top: 0; 
  -webkit-text-fill-color: #0000; 
  background: var(--gradient-blue);
  -webkit-background-clip: text;
  font-family: "Roboto Serif", serif;
  font-weight: 600;
}
.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 200px;
  flex-wrap: wrap;
}
.cont-dtl p {
  display: flex;
  font-size: 14px;
  line-height: 23px;
}
.cont-dtl p span {
  font-weight: normal;
}
.cont-dtl p i {
  margin-right: 21px;
}
 
footer {
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/footer.png);
  background-size: 100% 100%;
  padding-top: 10px;
  padding-left: 150px;
}

.footer>div img {
  width: 330px;
}

.footer iframe {
  height: 200px;
  border: 5px solid #fff;
  box-shadow: 1px 2px 5px 0px #646464;
}

.app-cont {
  width: 70%;
}

.app-cont img {
  width: 110px !important;
}

.phone-img img {
  width: 100% !important;
}

.phone-img {
  width: 30%;
}

.quick-link ul {
  padding: 0 0px;
}

l {
  position: relative;
  list-style: none;
}

.quick-link ul li:before {
  left: 0;
  content: "";
  width: 11px;
  height: 21px;
 background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/footer-icon.png);
 background-repeat: no-repeat;
  position: absolute;
  border-radius: 2px;
  top: 11px;
  filter: invert();
}

.quick-link ul li {
  
  width: 50%;
  padding-left: 21px;
  list-style: none;
  position: relative;
  padding: 3px 5px 3px 20px;
  font-size: 16px;
  margin-bottom: 0;
}

.footer .d-flex {
  align-items: flex-end; 
}

.footer .link {
  background: var(--main-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 16px;
  width: max-content;
  max-width: 100%;
}

.copyright p {
  text-align: center;
  margin-bottom: 0;
  padding: 5px 0;
  font-size: 12px;
}
.mid-block{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.mid-block > div {
  height: 131px;
  width: 24%;
  margin: 10px;
  background: #fff;
  position: relative; 
  padding: 0;
  border-radius: 11px;
  transition: 0.5s;
} 
.mid-block .tab-view .tabs-block {
  height: 80%;
  box-shadow: 0px 0px 5px 0px #ddd;
  position: relative;
  display: block; 
  width: 100%;
  transition: 0.5s;
  border-radius: 11px;
}

.tab-view {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.tabs-block.active {
  /* transform: scale(1.2); */
  height: 120px !important;
}
.mid-block.align-item-top .tab-view {
  align-items: flex-start;
}
.mid-block .active img.faci-gif {
    display: block;
    width: 60px;
    filter: unset;
}
.mid-block > div img {
  width: 36px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  filter: grayscale();
  top: 50%;
}
.mid-block > div img.faci-gif {
  display: none;
}

.news-blk img {
  width: 100%;
}
.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  height: 210px;
  padding-bottom: 50px;
}
 
.innersec {
  min-height: 400px;
  padding: 20px 10px 40px 10px;
}
.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e; 
}
.innersec h1 {
  font-family: "Oleo Script", system-ui;
  font-size: 35px; 
  position: relative; 
  background:-webkit-linear-gradient(#d6639b, #3c8fed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 0px;
  margin-bottom: 45px;
  padding-left: 15px;
}
.innersec h1:before {
  background: -webkit-linear-gradient(#d6639b, #3c8fed);
  content: " ";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0;
  width: 5px;
}
.innersec h5 {
  font-size: 20px;
  color: #0664a8;
  line-height: 30px;
} 
 
ul.breadcrumbli {
  display: flex;
  margin-bottom: 50px;
  border-bottom: 0.5px solid #afafaf;
  padding: 4px 0;
}
ul.breadcrumbli li {
  position: relative;
  padding-left: 0;
  color: #000;
  list-style: none;
  font-size: 14px;
  padding-right: 20px;
}

ul.breadcrumbli li:before {
  position: absolute;
  content: ' / ';
  width: 100%;
  height: 100%;
  left: -13px;
  top: 0;
  color: #000;
}
ul.breadcrumbli li:first-child:before {
  content: '';
}
ul.breadcrumbli li a {
  color:#ff198b !important;
  position: relative;
  z-index: 9;
}

.msgbox {
  float: right;
  margin-bottom: 20px;
  margin-left: 30px;
  position: relative;
  width: 40%;
}
.msgbox img {
  border: 0px solid #bdbdbd;
  padding: 10px;
  position: relative;
  width: 100%;
  box-shadow: 0px 2px 10px 1px #ccc;
  border-radius: 5px;
}
.innersec .name span {
  color: #ff198b ;
  display: block;
  font-weight: 400;
}
.innersec .name {
  font-size: 16px;
  font-weight: 700;
}
.faclitibox h5 {
  font-family: "Oleo Script", system-ui;
  border-bottom: 1px dashed #5486bd;
  width: max-content;
  color: #214e8bc4;
}
.faclitibox h4 {
  background: linear-gradient(87deg, #29559838, transparent);
  width: max-content;
  padding: 15px 20px;
  font-size: 20px;
  font-family: cursive;
  margin-bottom: 25px;
  width: 100%;
  line-height: 30px;
}

ul.mainlist li {
  margin: 10px;
  line-height: 30px;
  font-size: 16px;
  list-style-type: none;
  position: relative;
  color: #464646;
  text-align: justify;
}
ul.mainlist li:before {
  content: "";
  font-size: 20px;
  color: #000;
  left: -5px;
  top: 6px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: -25px;
  position: absolute; 
  background-size: 100% 100%;
  height: 20px;
  width: 20px;
}
ul.mainlist {
  padding-left: 10px;
}
.schoolhours {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.schoolhours p {
  width: 48%;
  border-bottom: 1px solid #ccc;
  margin: 8px 10px;
  background: #f1f1f175;
  border-left: 5px solid #5477a6;
  padding: 5px 10px;
  box-shadow: 0px 1px 2px 1px #ccc;
}

.contactusbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contactusdesc {
  display: flex;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
}

.contactusicn i {
  color: #fff;
  font-size: 20px;
}

.contactusicn {
  padding-right: 16px;
}
.contactusdiv {
  width: calc(35% - 30px);
  padding: 40px 30px;
  border-radius: 20px;
  margin: 0px 5px 20px 0px;
}
.fstcontact {
  background:#295599;
}

.scdcontact {
  background: #e865a6;
}

.thrdcontact {
  background: #f6a440;
}
.contactusdesc p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.contactusimg h4 { margin-top: 25px;margin-bottom: 20px;color: #fff;font-size: 20px;line-height: 30px;}
.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.tc {
  width: 49%;
  margin-bottom: 30px;
}

.tcblock iframe {
  width: 49%;
}

.tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
}

.tc label p {
  margin-bottom: 0px;
}

.tc .form-group {
  margin-bottom: 20px;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
}

.viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background: var(--gradient-blue);
  transition: all 0.2s ease-in;
}

.viewdetailsbtn:hover {
  background: var(--gradient-pink);
}
.contactusimg img {
  width: auto;
}

.erp_sec .guideheading {
  text-align: left;
}

.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family: 'Roboto',sans-serif;
}

.assistance {
  background:rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a {
  color: #20488a;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}
 
.modal {
  background: #0000008a;
  z-index: 99999;
  padding-right: 0 !important;
}
h3.Subhead {
  color: #104677;
  font-size: 20px;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
 
    #chooseddlYear option { color: black;}
    .chooseyear  select{margin:0px;}
    .chooseyear  select{width:100%;background: unset;border: unset;height:28px;color:#fff;margin-top:15px;border: unset;border-bottom: 1px solid grey;}
    .clr{clear:both} 
    .video-gallery {
      padding: 20px;
      box-shadow: 0px 1px 4px 1px #03030330;
      margin-bottom: 20px;
      background: #e2edfc;
      border-radius: 3px;
  }
    .video-gallery a {
      display: flex;
      color: inherit;
      align-items: center;
    }
    .video-gallery a i {
     font-size: 40px;
     color: #ad0807;
     margin-right: 10px;
     display: flex;
    }
    .video-gallery a h3 {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 24px;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-top: 0;
      padding-left: 0;
  }
    .video-gallery p {
      font-size: 12px;
      margin-bottom: 0;
    }
    .video-gallery a h3:after {
      position: static;
    }
    .video-gallery a img {
      width: 52px;
      margin-right: 10px;
    }
    
.row.tabs-dynamic {
  background: #cbdcf1;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}
.row.tabs-dynamic p {
  margin-bottom: 0;
}
.row.tabs-dynamic p a {
  color: #585757;
}
.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}
.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}
.count-val p {
  margin-bottom: 0px;
}
.load-more .next {
  background: #273c5b;
  width: max-content;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
}

.innersec .news-head {display: flex;align-items: center;}
.innersec .news-head h2 {    padding-right: 20px; border-right: 1px solid #c5c5c5;  margin-right: 20px;  background: #e5e5e5; padding: 4px 10px;}
.innersec .news-head h3 {
  font-weight: 400;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
  color: #00599b;
  position: relative;
}
.innersec .news-blk {margin-bottom: 20px;padding-right: 30px;padding-bottom: 30px;width: 97%;box-shadow: 9px 11px 5px 0px #dddddd3d;position: relative;overflow: hidden;padding: 20px;box-shadow: 0px 0px 12px 0px #e7e7e7;border: 4px double #b5b5b5;transition: 0.5s;}
.innersec .news-blk:before {
  content: "";
  position: absolute;
  top: -40px;
  right: -8px;
  width: 100px;
  height: 100px;
  background: #ecf9ff;
  border-radius: 50%;
}
.innersec .attachment {
  background: #00bcd4;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 5px;
  }
.innersec .news-content p {margin-bottom: 6px;max-height: 160px;overflow-y: scroll;}
.innersec .news-content p::-webkit-scrollbar {width: 0;}
.innersec .news-blk:hover {
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}
.innersec .news-head h2 span {
  display: block;
  color: #153168;
  font-size: 18px;
  text-align: center;
}
.newsCount:nth-child(4n+1) > div {
  border-left: 3px solid #fff27e;
}
.newsCount:nth-child(4n+2) > div {
  border-left: 3px solid #ceb2ff;
}
.newsCount:nth-child(4n+3) > div {
  border-left: 3px solid #ff928f;
}
.newsCount:nth-child(4n+4) > div {
  border-left: 3px solid #ffb470;
}
 
 
.eventpgh { 
  padding-top: 13px;
  padding-right: 2px;
}
 
.eventdate {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 5px;
}
.eventpgh p {
  font-size: 15px;
  line-height: 30px;
}
.eventdate a {
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.eventdate span {
  font-size: 13px;
  line-height: 23px;
  color: #1c1b3b;
}
.eventpgh h5 {
  color: var(--main-color);
  line-height: 30px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
} 
.eventpgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
 
.eventpgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
} 
.eventattch {
    background: #1c1b3b;
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 5px 4px 5px;
    border-radius: 0px 0px 10px 10px;
    color: #fff;
}


.innerSection {
  padding: 50px 0 100px;
}
.breadcrumb {
  min-height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/sld_1.jpg); 
  position: relative;
  background-attachment: fixed;
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
}
.breadcrumb ul li {
  margin: 0 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.breadcrumb::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #00000085;
  left: 0;
  top: 0;
  position: absolute;
}

.breadcrumb ul {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: relative;
}
.message .owl-carousel {
  display: block; 
}
.message .owl-dots {
  display: none;
}
.content-clamp p{
  display: none;
}
.content-clamp p:nth-child(1),  .content-clamp p:nth-child(2),  .content-clamp p:nth-child(3),  .content-clamp p:nth-child(4) {
  display: block;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 9;
  overflow: hidden;
}
.form-section {
  position: fixed;
  z-index: 9;
  background: #0000003b;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  z-index: 99;
}
.form-section.active {
  display: flex;
}
.form-section .form-block {
  background: #fff;
  padding: 30px;
  width: 600px;
  border: 4px solid #247f84;
  border-radius: 14px;
  box-shadow: 0px 0px 20px 8px #0000005c;
  max-width: 95%;
}

.form-heading {
  text-align: center;
  margin-bottom: 40px;
  text-transform: uppercase;
  color: #208990;
}

.form-section .form-block .form-feilds label {
  display: block;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
}

.form-section .form-block .form-feilds {
  display: flex;
  flex-wrap: wrap;
}

.form-section .form-block .form-feilds > div {
  width: 50%;
  margin-bottom: 20px;
}

.form-section .form-block .form-feilds > div input, .form-section .form-block .form-feilds > div select, .form-section .form-block .form-feilds > div textarea {
  width: 97%;
  padding: 5px;
  margin-bottom: 0px; 
  border: 1px solid #ddd;
} 
p.success-message {
  color: green;
  text-align: center;
}
.message-feild {
  width: 100% !important;
}

.submit {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit input {
  width: max-content !important;
  margin: 20px;
  min-width: 100px;
  /* margin: auto; */
}
.form-section .form-block .form-feilds > div input[type="submit"] {
  background: #208990;
  color: #fff;
  border-radius: 5px;
  letter-spacing: 1px;
}
.close {
  float: right;
  font-weight: 800;
  font-size: 20px;
  margin-top: -11px;
}
.contact-blk {
  display: flex;
  /* align-items: center; */
  background: #2499a0;
  color: #fff;
  margin-bottom: 20px;
  box-shadow: 0px 19px 15px -20px #000;
  border-radius: 7px;
}

.contact-blk img {
  width: 40px;
  min-width: 40px;
}

.contact-blk .contact-info h6 {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #ffc799;
  font-size: 19px;
}

.contact-blk .contact-icon {
  margin-right: 20px;
}

.contact-blk .contact-info p {
  margin-bottom: 0;
}

.contact-blk .contact-icon {
  padding: 20px;
  background: #ededed;
  display: flex;
  align-items: center;
}

.contact-blk .contact-info {
  padding: 17px;
}
.innerSection iframe {
  border: 15px solid #fff;
  box-shadow: 0px 0px 5px #ddd;
}
.close, .online-reg {
  cursor: pointer;
}
.table-responsive table {
  width: 100%;
}

.table-responsive table td {
  padding: 9px;
  border: 1px solid #ddd;
}

.table-responsive table thead {
  background: #2499a0;
  color: #fff;
}

.table-responsive table thead th {
  font-weight: 400;
  padding: 9px;
  text-transform: uppercase;
  min-width: 130px;
}
p.error { 
  color: red;
  margin-bottom: 0;
}
.row.tabs-dynamic {
  background: #ebebeb;
  padding: 13px 0;
}

.row.tabs-dynamic p {
  margin-bottom: 0;
}

.row.tabs-dynamic input, .row.tabs-dynamic select {
  border: 1px solid #ddd;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  background: #fff;
}


.eventbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 13px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  border-radius: 4px;
  overflow: hidden;
}

.eventbox .eventboximg {
  width: 27%;
}

.eventbox .eventboxdesc {
  width: 70%;
}

.eventbox .eventboximg img {
  width: 100%;
  height: 160px;
  object-fit: contain;
  background: #ebebeb;
}

.eventbox .eventdate {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.eventbox .eventdate i {
  margin-right: 5px;
  color: var(--main-color);
}
.lazyload-wrapper {
  display: initial;
}
.eventbox .eventattch {
  position: absolute;
  right: 0;
  top: 0;
  background: var(--primary-color);
  color: #fff;
  padding: 2px 6px;
}
.evt-img  img {
  height: 180px;
  object-fit: contain;
  width: 100%;
  background: #e9e9e9;
}
.innerSection .news-blk {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
}

.news-blk .news-head {
  width: 13%;
}

.news-blk .news-content {
  width: 84%;
}

.news-blk  .attachment {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--main-color);
  color: #fff;
  padding: 3px 7px;
}

.news-blk .news-head {
  text-align: center;
  background: var(--gradient-blue);
  color: #fff;
  padding: 40px 5px;
}

.news-blk .news-head span {
  display: block;
}

.news-blk .news-content h3 {
  font-size: 21px;
}

.news-blk .news-content p {
  margin-bottom: 0;
  font-size: 14px;
}

.news-blk .news-head h2 {
  font-size: 24px;
}

.gal_new {
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #ddd;
  margin-top: 20px;
  position: relative;
}

.gal_new img.img-fluid {
  height: 210px;
  object-fit: contain;
  background: #dbdbdb;
}

.gal_new h4.catgry-name {
  font-size: 20px;
  margin-top: 20px;
} 

.gal_new .total-img {
  background: var(--gradient-blue);
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  padding:  5px 10px;
  box-shadow: 7px 5px 11px -10px #000;
}

.gal_new .total-img p {
  margin-bottom: 0;
} 

.gal_new h6.date {
  font-size: 14px;
}

.gal_new h6.date span {
  float: right;
  font-size: 20px;
}
.sub_gallery img{
  height: 200px;
  background: #ddd;
  object-fit: contain;
  box-shadow: 0px 0px 5px 0px #ddd;
  padding: 5px;
  width: 100%;
  margin-top: 20px;
} 

.discription {
    text-align: center;
    margin-top: 20px;
}

.discription h3.subhead {
    font-size: 22px;
    color: var(--primary-color);
}
.video-gallery a {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.video-gallery a i {
  font-size: 30px;
  margin: 20px;
  color: red;
}

.video-gallery {
  margin-top: 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 5px;
}

.video-gallery a h3 {
  font-size: 18px;
}

.video-gallery a p {
  margin-bottom: 0;
  font-size: 13px;
}

.popup {
  padding-right: 0 !important;
}
/* .popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/SMSMU/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/SMSMU/public/Images/righticn.png') no-repeat !important; } */
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:40%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #0a4685; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.popup .carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.popup .carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.popup .carousel-item .modalcard .card-body { padding: 10px }
.popup .carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.popup .carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.popup .carousel-item button.btn-close:focus { box-shadow: none }
.popup .carousel-item a.btn.btn-primary.btn-sm { border-radius: 6px;
  box-shadow: -2px 14px 11px -9px #0000008a; 
  transition: .5s; background: linear-gradient(180deg, #0f6a70, #4abcc3); border: #0f6a70; margin: 0 10px; color: #fff!important ;     padding: 10px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;}
  .popup .carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #0f6a70; }
  .popup .carousel-item a.btn.btn-primary.btn-sm:hover {
  background: linear-gradient(180deg, #0f6a70, #4abcc3);
}
.popup .carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #0f6a70;right: 0;}
.popup .carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/SMSMU/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.popup .modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
 
.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.innerSection .title {
  align-items: center;
  justify-content: center;
}
.abt-sch {padding: 8px;background: var(--gradient-blue);border-radius: 6px;box-shadow: 5px -20px 20px -28px #000, 5px 20px 20px -28px #000;margin-top: 20px;}
.our-principal {
  box-shadow: 1px 40px 35px -49px #000;
  padding: 8px;
  text-align: center;
  margin-bottom: 30px;
  border-radius: 6px;
  border: 6px solid #f5f5f5;
}

.our-principal img {
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

.our-principal h4 {
  font-size: 20px;
  text-align: center;
  color: var(--main-color);
}

.our-principal p {
  margin-bottom: 0;
}
.innerSection h3{
  font-size: 22px;
  color: var(--main-color);
}
.m-com img {
  margin: auto;
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.m-com {
  text-align: center;
  border: 9px solid #fff;
  box-shadow: 0px 0px 14px 0px #00000026;
  margin-bottom: 30px;
  border-radius: 18px;
  overflow: hidden;
}
.management_commit img { 
  margin: auto;
  display: block;
  margin-bottom: 21px;
  /* border: 2px solid #ddd; */
  /* border-radius: 38px; */
  /* background: #ddd; */
  height: 250px;
  object-fit: contain;
}

.management_commit {
  text-align: center;
  box-shadow: 2px -30px 39px -51px #000000, 2px 30px 29px -40px #000000;
  padding: 20px;
  width: 95%;
  margin: auto;
  border-radius: 8px;
  border: 1px solid #ddd;
  transition: 1s;
  background: linear-gradient(0deg, #ffffff , #ffffff );
}

.management_commit p {
  margin-bottom: 0;
} 

.col-md-4.mt-5 .management_commit img {
  height: 200px;
}

.management_commit h3 {
  font-family: "Roboto Serif", serif;
}

.management_commit:hover {
  background: var(--gradient-light); 
}
.faculty img {
  margin-bottom: 20px;
  height: 200px;
  object-fit: contain;
  width: 100%;
}
.syllabus-tab h3{
  -webkit-line-clamp: 5 !important;
}
.faculty {
  text-align: center;
  border: 7px solid #f7f7f7;
  box-shadow: 0px 3px 15px -13px #000;
  margin-bottom: 20px;
  padding: 10px 0;
}

.faculty p {
  margin-bottom: 0;
  text-transform: uppercase;
}
.labs-box {
  padding: 5px;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 1px 19px 9px -19px #cbcbcb, 1px -19px 5px -15px #cbcbcb2b;
  border-radius: 5px;
  border-radius: 12px;
  border: 1px solid #dddddd61;
  padding: 9px;
}

.labs-box img {
  background: var(--gradient-blue);
  padding: 5px;
  margin-bottom: 16px;
  border-radius: 9px;
}
.topper-pg {
  text-align: center;
  text-transform: uppercase; 
  margin-bottom: 30px;
  background: #ebebeb; 
  border-radius: 20px;
}
.topper-service img {
  margin-bottom: 20px;
}
.topper-pg i {
  color: #20833c;
  font-size: 5rem;
}

.topper-pg h3 {
  background: var(--gradient-light);
  padding: 10px;
}

.topper-pg p {
  padding: 20px;
}
.topper-service img {
  width: 150px;
  height: 150px;
  object-fit: contain;
  border-radius: 50%;
  border: 2px solid #b5b5b5;
}

.topper-service {
  background: #dddddd59;
  padding: 11px;
  border-radius: 5px;
}

.topper-service  p {
  margin-bottom: 0;
}
.audio-service {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 20px;
  box-shadow: 0px 0px 5px 0px #ddd;
  border-radius: 7px;

}

.audio-service img.img-fluid {
  margin-right: 20px;
  width: 80px;
}

.audio-service p {
  margin-bottom: 0;
}
.contact-container {
  display: flex;
  flex-wrap: wrap;
}

.contact-container > div {
  width: 30%;
  margin: 1%;
  text-align: left;
  margin-top: -60px;
}
.table-responsive img {
  width: 70px;margin: auto; display: block;

}
.image_blk.float-md-start.msgimg img {
  margin-right: 30px;
  border: 6px solid #fff;
  box-shadow: 0px 0px 11px 0px #b5b5b5;
}

.image_blk.float-md-start.msgimg {
  text-align: center;
}

.image_blk.float-md-start.msgimg p {
  color: green;
  font-size: 17px;
  text-transform: uppercase;
}
.innerSection ul{
  padding-left: 0;
  list-style: none;
}
.innerSection ul li{
  position: relative;
  padding-left: 40px;
}
.innerSection ul li:before {
  content: "";
  width: 30px;
  height: 30px;
  background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/arrow-right.png);
  position: absolute;
    left: 0;
    top: 0;
    background-size: 100%;

}
.msgimg {
  margin-left: 20px;
  border: 9px solid #fff;
  box-shadow: 0px 0px 13px 0px #00000024;
}
.innertopper{
  margin-bottom: 20px;
}
/* Flip Book */
.flipPageComponent {
  margin: auto;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.flipOpen.activebook img{
  width: 100%;
}
.buttonDesign {
  position: absolute;
  bottom: 0;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.flipOpen.activebook {
  position: relative;
  padding-bottom: 110px;
  margin-top: 70px;
}
.flip-block img {
  width: 100%;
}
.flip-block h3 {
  text-align: center;
}

.flip-block .magazine-download {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.flip-block .magazine-download > div {
  background: #268a3e;
  color: #fff;
  padding: 2px 13px;
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 5px;
}

.flip-block {
  box-shadow: 0px 0px 17px -10px #000;
  padding: 15px;
  border-radius: 5px;
}
.buttonDesign button {
  border: 0;
  margin: 7px;
  padding: 2px 11px;
  color: #ce1014;
  font-weight: 400;
  font-size: 19px;
  background: #ededed;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ddd;
}
/* Flip Book */


/* flip book */
/* .flip-popup { 
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 20px;
}

.flip-popup.active {
  display: flex;
}

.closeme {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 30px;
  color: #fff;
  background: red;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
}
 

.flipOpen {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flipPageComponent {
  max-width: 90%;
  max-height: 90%;
  position: relative;
  overflow: hidden; 
  margin: auto;
}

.page img {
  width: 100%;
  height: auto;
  display: block;
}

.flip-block img {
  width: 100%;
  margin-bottom: 20px;
}

.control-btn {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.control-btn div {
  cursor: pointer;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.stf__block > div img {
  width: 100%;
}
 

.d-flex.flipSelection{
  margin-top: 10px;
} 
.d-flex.flipSelection .tab-pane .flip-block.showflip {
  padding: 12px;
}
.flip-block h3 {
  text-align: center;
  margin-bottom: 20px;
}
 */


.bg-color-span{
  background-color: rgba(0, 0, 0, 0);
} 

.footer-widget .card-body img {
  width: 110px;
  margin-top: 5px;
}

.footer-widget .card-body h4 {
  color: #fff;
}

.footer-widget .card-body h5 {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
}
.footer-widget .card-body h5 a {
  color: #fff;
}
/* flip book */

button.selectionTabs {
  display: block;
  width: 100%;
  margin: 16px 0;
  border: 0px;
  padding: 14px 8px;
  background: var(--gradient-blue);
  color: #fff;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 14px;
  box-shadow: 0px 20px 5px -18px #898989;
}
.birthday-block .slick-slider.slick-initialized {
  width: 80%;
  margin: auto;
}

button.selectionTabs.active {
  background: var(--gradient-light);
  color: #000;
}
@media(max-width: 1600px) {
  footer{padding-left: 0;}
  .header-contact .border-tab {
    font-size: 13px;
  } 

  .footer .d-flex {
    align-items: flex-end;
    margin-top: 00px;
  }

  .faci-blk {
    width: 98%;
    padding: 40px 10px;
    margin-bottom: 10px;
  }

  .topper-img {
 
    padding: 43px 20px;
  }
}


@media(max-width: 1400px) {
  .quick-link ul li { 
    padding: 0px 20px;
    font-size: 14px;  
}
  .footer {
    background: url(https://webapi.entab.info/api/image/SMSMU/public/Images/footer.png);;
    background-size: 100% auto;
    padding-top: 10px;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100%;
}
footer {
  background: #70cbf1;
}
.footer iframe { 
  margin-top: 20px;
}
  .contact-tab > div {
    margin: 0 7px;
}
.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 190px;
  flex-wrap: wrap;
}  
.evt-img img {
  height: 260px;
  object-fit: contain;
  background: #ddd;
}
 
.header-blk { 
  width: 100%; 
}
.col-md-2.highlight-tab .space-even img {
  width: 16px !important;
}
  .header-contact > div {
    width: 42%;
    font-size: 14px;
}
.logo img {
  width: 370px; 
}
.header-contact >div img {
height: 15px;
}

  .footer .link {
    font-size: 13px;
  }

  .evt-content {
    padding: 20px 6px 2px;
  }

  .evt-content p:last-child {
    font-size: 13px;
  }

  .topper-img img {
    width: 90px !important;
    margin: auto;
    height: 90px;
  }
}

@media(max-width: 1200px) {
  .schoolhours p {
    width: 49%; 
    margin: 8px 4px;
  }
  .news { 
    margin-bottom: 40px;
}
  .announcement{
    display: none;
  }
  .sub-submenu{
    padding: 9px 12px;
  }
  .submenu li {
    color: #fff;
    margin: 0 0px;
    font-size: 14px;
}
.sub-submenu li {
  color: #000 !important;
}
  .online-reg {

    width: 140px;
  }

  .patrons p {
    font-size: 20px;
  } 

  .min-ht {
    min-height: 370px;
  }

  .footer .d-flex {
    margin-top: 0;
  }

  .news {
    width: 65%;
    margin: auto; 
    border-radius: 6px;
  }
}

@media(max-width: 991px) {
  .breadcrumb {
    background-size: auto 50%;
    background-position: top center;
}
  .contactusdiv {
    width: calc(53% - 30px);
}
.contactusbox {
    justify-content: center;
}
  .schoolhours p {
    width: 48%;
  }
  .msgbox {
    width: 50%;
}
  .news{
    margin-bottom: 20px;
  }
  .events {
    width: 100%; 
}
.message {
  width: 90%;
  padding: 80px 0 20px;
  margin: auto;
}
  .vision-mission{
    width: 100%;
    margin: auto;
  }
  .vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight{
    width: 95%;
    min-height: auto;
  }
  .message-block{
    flex-direction: column-reverse;
  }
  .message-block > div:nth-child(1){
    width: 100%;
  }
  .message-block > div:nth-child(2) {
    width: 100%;
    margin: 0;
    object-fit: contain;
    height: 300px;
    position: relative;
}

.message-block > div:nth-child(2) img {
    max-height: unset;
    height: 300px;
    object-fit: contain;
}
  .vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight { 
    margin-top: 20px; 
}
  .submenu li i.bi.bi-chevron-down{
    float: right;
  }
  .sub-submenu {
    position: static;
    margin-top: 16px;
}

.sub-submenu li {
    color: #000;
}
  .desk-only{
    display: none;
  }
  .header-contact{
    flex-wrap: wrap;
    padding-bottom: 0;
    margin-top: 0;
  } 

  .header-contact > div { 
    margin-bottom: 20px;
}
  
  .col-md-2.highlight-tab {
    display: none;
}
  .submenu {
    list-style-type: none;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1;
    display: block;
    border: none;
    width: 100%;
    flex-direction: column;
    padding: 20px 0;
    width: 98%;
    margin: 0 1%;
    box-shadow: -1px 1px 5px 0px #000000a1;
    z-index: 99;
  }

  .menu-toggle {
    display: block;
    background: var(--main-color);
    z-index: 9999;  
    border-radius: 3px; 
    margin-left: auto;
    padding: 3px 8px;
    font-size:20px;

  }
  .submenu li{
      width: 100%;
      padding:5px 20px;
      border: 0;
  }
  .menu{
    position: relative;
    float: unset;
    height: auto;
    background: #ddd;
  }
  .logo img {
    width: 280px;
  }
 

  .online-reg {
    right: 0;
    top: 50%;
    transform: translate(-10%, -117%);
  }

  .phone-img img {
    width: 52% !important;
  }

 

  .lg-mid {
    margin-bottom: 20px;
  }

  .news-area {
    margin-bottom: 20px;
  }
 

  .vision-mission:before {
    width: 90%;
    height: 2px;
  }

  

   

  .app-cont img {
    width: 120px !important;
  }

  .footer .link {
    text-align: center;
    font-size: 13px;
  }
}
.notification {
  position: relative;
  z-index: 99989;
}
@media(max-width: 767px) {
  .news {
    width: 95%; 
}
  .breadcrumb ul li {
    margin: 0 4px; 
    font-size: 12px;
}
.row.tabs-dynamic input, .row.tabs-dynamic select { 
  margin-bottom: 3px;
}
  .erpboximg {
    width: 100%;
}

.erpboxdesc {
    width: 100%;
}
  .tcblock iframe {
    width: 100%;
}
.tc {
    width: 100%;
}
  .contactusdiv {
    width: 100%;
    padding: 20px 15px;
}
  .schoolhours p {
    width: 100%;
}

.msgbox {
  margin-top: 20px;
  width: 100%;
  float: none;
  margin-left: 0px;
}
  .gallery-blk img{
    height: auto;
  }
  .quick-link ul { 
    height: auto; 
}
.contact-tab{
  display: none;
}
.logo img {
  width: 100%;
}
.message, .news-event>div{
  width: 99%; 
}
.footer{
  width: 99%;
  margin: 0px auto 0px;
}
  .about-us {
    margin-top: 0;
  }
  .popup .modal-dialog {
    min-width: 80%;
}
 
  .patrons p {
    font-size: 28px;
  }

  .patrons-cont {
    height: auto;
  }

  .min-ht {
    min-height: 290px;
  }

  .news {
    padding-bottom: 20px;
  }

  .topper-img {
    background-size: 67% 86%;
    padding: 43px 20px;
  }

  .vision-mission {
    position: relative;
    top: 0;
    width: 96%;
  }

  .tpr-block {
    padding-left: 30px;
  }

  .topper-img img {
    width: 110px !important;
    height: 110px;
  }
}

@media(max-width:600px) {
 .logo img { 
      margin-left: 0;
  }
  .vision-mission h3,
  .title h3 {
    font-size: 24px;
  }

  .patrons p {
    font-size: 22px;
  }

  .phone-img img {
    width: 100% !important;
  }

  .topper-img {
    background-size: 50% 86%;
  }
 

  .vision-mission {
    position: static;
    width: 95%;
    padding: 10px;
  }

  p {
    font-size: 14px;
  }

  .news-bg {
    background: unset;
  }

  .news {
    width: 100%;
    margin: auto;
    padding: 30px 30px 0px;
    border-radius: 6px;
    
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .events {
    width: 100%
  }

  .news-blk {
    margin-bottom: 20px;
  }

  .min-ht {
    min-height: 190px;
  }

  .online-reg {
    width: 140px;
  }
}
@media(max-width: 576px) {
.eventboximg {
  width: 100%;
}
.eventboxdesc {
  width: 100%;
  padding: 0px 10px;
}
}
@media(max-width: 400px) {
  .ph-only {
    display: none;
}
.menu {
  height: auto;
  float: unset;
}

.menu-toggle {
  position: static;
  width: 100%;
  padding: 2px;
  border-radius: 0;
  text-align: right;
  padding: 2px 11px;
}

.header-contact > div {
    margin-bottom: 0 !important;
}
.header-blk {
  padding: 8px 8px 20px;
}
  .app-cont,
  .phone-img {
    width: 100%;
  }

  .min-ht {
    min-height: 120px;
  }

  .online-reg {
    width: 110px;
  }
  .flipPageComponent.stf__parent {
    margin-top: -1% !important; 
    min-width: 100% !important;
}
    .stf__item{
        width: 100% !important;
        left: 50% !important;
        transform: translate(-50%, 0%);
    }
    .flip-popup {padding-top: 100px;}
}